import { BaseWidget, Condition } from '@draftkings/widgets-core';
import { SportSliderWidgetConfig, ISportSlider, IState, ConditionKeys, TrackEventProperties } from '../contracts';
import { ClankRetriver, SherpaRetriver } from '../retriever';
import { ContractTypes, LeagueNavRequestResolver, WidgetZonesRequestResolver } from '@draftkings/dk-data-layer';
import { Parser } from '../parser';
import { State } from '../state';
import { App } from '../components/App/App';
import { getDefaultValue, getTruthyValue } from '@draftkings/sportsbook-common-utils';
import { getWebHeaders } from '../utils';

export class SportSlider extends BaseWidget implements ISportSlider {
    private dataProvider: IState;
    constructor(domId: string, options: SportSliderWidgetConfig) {
        super(domId, (err) => console.error(err));
        const condition = new Condition(ConditionKeys);
        let retriever;
        if (options.sliderType === 'event') {
            retriever = new SherpaRetriver({
                leagueNavRequestResolver: new LeagueNavRequestResolver({
                    baseUrl: options.productConfig.navigationBff,
                    requestTimeout: getDefaultValue(options.requestTimeout, 120000),
                    consumerMetadata: getWebHeaders(options.consumerVersion)
                }),
                SportsbookLeague: options.dkDataLayer.SportsbookLeague,
                leagueId: options.fetchId,
                condition,
                defaultData: {
                    events: new Map<string, ContractTypes.SportEvent>(options.events.map((event) => [event.id, event])),
                    leagues: new Map<string, ContractTypes.League>(options.leagues.map((league) => [league.id, league]))
                },
                consumerVersion: options.consumerVersion,
                logError: options.logError,
                trackEvent: options.trackEvent
            });
        } else {
            retriever = new ClankRetriver({
                widgetZonesRequestResolver: new WidgetZonesRequestResolver({
                    baseUrl: `${options.productConfig.clankBaseApiHost.replace(
                        /api\/widgets\/markets\/.*/gi,
                        ''
                    )}sites/${getDefaultValue(
                        options.siteExperience,
                        `${options.productConfig.geoCodeEmbeddedResourceId}-SB`
                    )}`,
                    requestTimeout: getTruthyValue(options.requestTimeout, 120000),
                    consumerMetadata: getWebHeaders(options.consumerVersion)
                }),
                SportsbookEvent: options.dkDataLayer.SportsbookEvent,
                zoneId: options.fetchId,
                condition,
                widgets: options.widgets.filter((widget) => widget.widgetType === 'Event'),
                defaultData: {
                    events: new Map<string, ContractTypes.SportEvent>(options.events.map((event) => [event.id, event])),
                    leagues: new Map<string, ContractTypes.League>(options.leagues.map((league) => [league.id, league]))
                },
                consumerVersion: options.consumerVersion,
                logError: options.logError,
                trackEvent: (eventName: string, properties: TrackEventProperties) => {
                    if (options.productConfig.isEventPushTelemetryEnabled) {
                        return options.trackEvent(eventName, properties);
                    }
                }
            });
        }
        const parser = new Parser({
            retriever,
            widgets: options.widgets,
            sports: options.sports,
            logos: options.logos,
            productConfig: options.productConfig,
            sliderType: options.sliderType,
            id: options.currentId,
            excludeId: options.currentId,
            staticS3Host: options.staticS3Host
        });

        this.dataProvider = new State({
            parser,
            messageBus: options.messageBus,
            condition
        });

        this.component = <App dataProvider={this.dataProvider} renderLoader={options.renderLoader} />;
    }

    activate() {
        super.activate();
        this.dataProvider.activate();
        return this;
    }

    deactivate() {
        super.deactivate();
        this.dataProvider.deactivate();
        return this;
    }
}
